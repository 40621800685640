@media (max-width:1920px) {
  /* 1920 */
  .div-form {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    text-align: start;
    background-color: #0E0326;
    margin-left: 5em;
    margin-right: 5em;
    margin-top: 5em;
    border-radius: 2em;
  }
  /* 1920 */
  .div-form1 {
    padding-top: 2em;
    padding-bottom: 2em;  
  }
  /* 1920 */
  .text-form {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.2em;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1em;
    padding: 0em;
  }
  /* 1920 */
  .text-form-input {
    border-radius: 0.5em;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    margin-left: 0em;
    padding-right: 0.3em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    padding-left: 0.3em;
    color: #000;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1em;
    width: 30em;
  }
  /* 1920 */
  .text-form-input-ass {
    border-radius: 0.5em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0em;
    padding-right: 0.3em;
    padding-top: 0.3em;
    padding-bottom: 16em;
    padding-left: 0.3em;
    color: #000;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1em;
    width: 30em;
  }
  /* 1920 */
  .image-boy {
    width: 35em;
  }
  /* 1920 */
  .form-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #FDEB1A;
    border-radius: 0.5em;
  }
  /* 1920 */
  .text-form-button {
    border-radius: 0.5em;
    margin-top: 0em;
    margin-bottom: 0em;
    margin-left: 0em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-right: 0.2em;
    padding-left: 0.2em;
    color: #1A0A3D;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 1.5em;
    width: 10em;
  }
    /* 1920 */
  .div-input-ajuda {
    margin-top: 4em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 2em;
    margin-right: 2em;
    border-radius: 2em;
  }
  .div-ajuda {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #0E0326;
    margin-left: 14em;
    margin-right: 14em;
    padding-left: 2em;
    border-radius: 2em;
  }
  /* 1920 */
  .text-form-input-ajuda {
    border-radius: 0.5em;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    margin-left: 0em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 0.3em;
    padding-left: 0.5em;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    width: 28em;
    font-size: 2em;
    background-color: #0E0326;
    border: none;
    outline: none;
  }
  /* 1920 */
  input::placeholder {
    /* Adicione estilos desejados aqui */
    color: #FFF; /* Cor do texto do placeholder */
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.3em;
  }
  /* 1920 */
  .img-lupa {
    width: 3em;
  }
  .div-comp-img {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: 10em;
    margin-right: 10em;
    margin-top: 1em;
  }
  .div-img {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2em;
    margin-right: 2em;
    margin-top: 3em;
    margin-bottom: 3em;  
    background-color: #0E0326;
    border-radius: 2em;
    flex-wrap: wrap;
    width: 20em;
    padding-top: 1em;
    padding-bottom: 4em;
  }
  /* 1920 */
  .div-img-tv {
  width: 15em;
  padding-top: 2em;
  padding-bottom: 0em;
  padding-right: 2em;
  padding-left: 2em;
  margin-top: 0em;
  margin-left: 0em;
  margin-right: 0em;
  flex-wrap: wrap;

  }
  .img-text-tv {
    top: 50%; /* Alinha verticalmente ao centro da imagem */
    left: 60%; /* Alinha horizontalmente ao centro da imagem */
    transform: translate(0%, -50%); /* Centraliza o texto */
    color: #2091C9; /* Cor do texto */
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.6em; /* Tamanho da fonte do texto */
    margin-top: 0em;
    margin-bottom: 0em;
  }
  .img-sub-tv {
    position: absolute;
    top: 85%; /* Alinha verticalmente ao centro da imagem */
    left: 50%; /* Alinha horizontalmente ao centro da imagem */
    transform: translate(-50%, -50%); /* Centraliza o texto */
    color: #FFF; /* Cor do texto */
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 0.8em; /* Tamanho da fonte do texto */
    flex-wrap: wrap;
    
    width: 20em;
  }
}

@media (max-width:1500px) {
    /* 1500 */
    .div-form {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
      text-align: start;
      background-color: #0E0326;
      margin-left: 5em;
      margin-right: 5em;
      margin-top: 5em;
      border-radius: 2em;
    }
    /* 1500 */
    .div-form1 {
      padding-top: 2em;
      padding-bottom: 2em;
      margin-left: 2em;
    }
    /* 1500 */
    .text-form {
      color: #FFF;
      font-family: 'Barlow';
      font-weight: 400;
      font-size: 1.2em;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 1em;
      padding: 0em;
    }
    /* 1500 */
    .text-form-input {
      border-radius: 0.5em;
      margin-top: 0.6em;
      margin-bottom: 0.6em;
      margin-left: 0em;
      padding-right: 0.3em;
      padding-top: 0.3em;
      padding-bottom: 0.3em;
      padding-left: 0.3em;
      color: #000;
      font-family: 'Barlow';
      font-weight: 400;
      font-size: 1em;
      width: 30em;
    }
    /* 1500 */
    .text-form-input-ass {
      border-radius: 0.5em;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 0em;
      padding-right: 0.3em;
      padding-top: 0.3em;
      padding-bottom: 16em;
      padding-left: 0.3em;
      color: #000;
      font-family: 'Barlow';
      font-weight: 400;
      font-size: 1em;
      width: 30em;
    }
    /* 1500 */
    .image-boy {
      width: 35em;
    }
    /* 1500 */
    .form-button {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      background-color: #FDEB1A;
      border-radius: 0.5em;
    }
    /* 1500 */
    .text-form-button {
      border-radius: 0.5em;
      margin-top: 0em;
      margin-bottom: 0em;
      margin-left: 0em;
      padding-top: 0.2em;
      padding-bottom: 0.2em;
      padding-right: 0.2em;
      padding-left: 0.2em;
      color: #1A0A3D;
      font-family: 'Barlow';
      font-weight: 600;
      font-size: 1.5em;
      width: 10em;
    }
  /* 1500 */
  .div-input-ajuda {
    margin-top: 4em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 2em;
    margin-right: 2em;
    border-radius: 2em;
  }
  /* 1500 */
  .div-ajuda {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #0E0326;
    margin-left: 14em;
    margin-right: 14em;
    padding-left: 2em;
    border-radius: 2em;
  }
  /* 1500 */
  .text-form-input-ajuda {
    border-radius: 0.5em;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    margin-left: 0em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 0.3em;
    padding-left: 0.5em;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    width: 28em;
    font-size: 1.6em;
    background-color: #0E0326;
    border: none;
    outline: none;
  }
  /* 1500 */
  input::placeholder {
    /* Adicione estilos desejados aqui */
    color: #FFF; /* Cor do texto do placeholder */
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.3em;
  }
  .img-lupa {
    width: 2em;
  }
}

@media (max-width:1024px) {
      /* 1024 */
      .div-form {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        text-align: start;
        background-color: #0E0326;
        margin-left: 5em;
        margin-right: 5em;
        margin-top: 5em;
        border-radius: 2em;
      }
      /* 1024 */
      .div-form1 {
        padding-top: 2em;
        padding-bottom: 2em;
        margin-left: 2em;
      }
      /* 1024 */
      .text-form {
        color: #FFF;
        font-family: 'Barlow';
        font-weight: 400;
        font-size: 1.2em;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 1em;
        padding: 0em;
      }
      /* 1024 */
      .text-form-input {
        border-radius: 0.5em;
        margin-top: 0.6em;
        margin-bottom: 0.6em;
        margin-left: 0em;
        padding-right: 0.3em;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
        padding-left: 0.3em;
        color: #000;
        font-family: 'Barlow';
        font-weight: 400;
        font-size: 1em;
        width: 20em;
      }
      /* 1024 */
      .text-form-input-ass {
        border-radius: 0.5em;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0em;
        padding-right: 0.3em;
        padding-top: 0.3em;
        padding-bottom: 8em;
        padding-left: 0.3em;
        color: #000;
        font-family: 'Barlow';
        font-weight: 400;
        font-size: 1em;
        width: 20em;
      }
      /* 1024 */
      .image-boy {
        width: 18em;
      }
      /* 1024 */
      .form-button {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: #FDEB1A;
        border-radius: 0.5em;
      }
      /* 1024 */
      .text-form-button {
        border-radius: 0.5em;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 0em;
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        padding-right: 0.2em;
        padding-left: 0.2em;
        color: #1A0A3D;
        font-family: 'Barlow';
        font-weight: 600;
        font-size: 1.5em;
        width: 10em;
      }
        /* 1024 */
  .div-input-ajuda {
    margin-top: 4em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 2em;
    margin-right: 2em;
    border-radius: 2em;
  }
  /* 1024 */
  .div-ajuda {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #0E0326;
    margin-left: 14em;
    margin-right: 14em;
    padding-left: 2em;
    border-radius: 2em;
  }
  /* 1024 */
  .text-form-input-ajuda {
    border-radius: 0.5em;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    margin-left: 0em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 0.3em;
    padding-left: 0.5em;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    width: 22em;
    font-size: 1.6em;
    background-color: #0E0326;
    border: none;
    outline: none;
  }
  /* 1024 */
  input::placeholder {
    /* Adicione estilos desejados aqui */
    color: #FFF; /* Cor do texto do placeholder */
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.3em;
  }
  .img-lupa {
    width: 2.5em;
  }
}

@media (max-width:768px) {
  /* 768 */
  .div-form {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: start;
    background-color: #0E0326;
    margin-left: 2.5em;
    margin-right: 2.5em;
    margin-top: 5em;
    border-radius: 2em;
  }
  /* 768 */
  .div-form1 {
    padding-top: 2em;
    padding-bottom: 2em;
    margin-left: 2em;
  }
  /* 768 */
  .text-form {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.2em;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1em;
    padding: 0em;
  }
  /* 768 */
  .text-form-input {
    border-radius: 0.5em;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    margin-left: 0em;
    padding-right: 0.3em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    padding-left: 0.3em;
    color: #000;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1em;
    width: 20em;
  }
  /* 768 */
  .text-form-input-ass {
    border-radius: 0.5em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0em;
    padding-right: 0.3em;
    padding-top: 0.3em;
    padding-bottom: 8em;
    padding-left: 0.3em;
    color: #000;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1em;
    width: 20em;
  }
  /* 768 */
  .image-boy {
    width: 15em;
  }
  /* 768 */
  .form-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #FDEB1A;
    border-radius: 0.5em;
  }
  /* 768 */
  .text-form-button {
    border-radius: 0.5em;
    margin-top: 0em;
    margin-bottom: 0em;
    margin-left: 0em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-right: 0.2em;
    padding-left: 0.2em;
    color: #1A0A3D;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 1.5em;
    width: 10em;
  }
}

@media (max-width:540px) {
  /* 540 */
  .div-form {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: start;
    background-color: #0E0326;
    margin-left: 2em;
    margin-right: 2em;
    margin-top: 3em;
    border-radius: 2em;
    justify-content: space-around;
  }
  /* 540 */
  .div-form1 {
    padding-top: 2em;
    padding-bottom: 2em;
    margin-left: 0em;
  }
  /* 540 */
  .text-form {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.2em;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1em;
    padding: 0em;
  }
  /* 540 */
  .text-form-input {
    border-radius: 0.5em;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    margin-left: 0em;
    padding-right: 0.3em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    padding-left: 0.3em;
    color: #000;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1em;
    width: 20em;
  }
  /* 540 */
  .text-form-input-ass {
    border-radius: 0.5em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0em;
    padding-right: 0.3em;
    padding-top: 0.3em;
    padding-bottom: 8em;
    padding-left: 0.3em;
    color: #000;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1em;
    width: 20em;
  }
  /* 540 */
  .div-boy {
    margin-top: 1.5em;
  }
  /* 540 */
  .image-boy {
    width: 20em;
  }
  /* 540 */
  .form-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #FDEB1A;
    border-radius: 0.5em;
  }
  /* 540 */
  .text-form-button {
    border-radius: 0.5em;
    margin-top: 0em;
    margin-bottom: 0em;
    margin-left: 0em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-right: 0.2em;
    padding-left: 0.2em;
    color: #1A0A3D;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 1.5em;
    width: 10em;
  }
  /* 540 */
  .div-input-ajuda {
    margin-top: 4em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 2em;
    margin-right: 2em;
    border-radius: 2em;
  }
  /* 540 */
  .div-ajuda {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #0E0326;
    margin-left: 14em;
    margin-right: 14em;
    padding-left: 2em;
    border-radius: 2em;
  }
  /* 540 */
  .text-form-input-ajuda {
    border-radius: 0.5em;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    margin-left: 0em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 0.3em;
    padding-left: 0.5em;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    width: 12em;
    font-size: 1.6em;
    background-color: #0E0326;
    border: none;
    outline: none;
  }
  /* 540 */
  input::placeholder {
    /* Adicione estilos desejados aqui */
    color: #FFF; /* Cor do texto do placeholder */
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.3em;
  }
  .img-lupa {
    width: 2.5em;
  }
}

@media (max-width:480px) {
  /* 480*/
  .div-form {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: start;
    background-color: #0E0326;
    margin-left: 2em;
    margin-right: 2em;
    margin-top: 5em;
    border-radius: 2em;
    justify-content: space-around;
  }
  /* 480 */
  .div-form1 {
    padding-top: 2em;
    padding-bottom: 2em;
    margin-left: 0em;
  }
  /* 480 */
  .text-form {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.2em;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1em;
    padding: 0em;
  }
  /* 480 */
  .text-form-input {
    border-radius: 0.5em;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    margin-left: 0em;
    padding-right: 0.3em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    padding-left: 0.3em;
    color: #000;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1em;
    width: 13em;
  }
  /* 480 */
  .text-form-input-ass {
    border-radius: 0.5em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0em;
    padding-right: 0.3em;
    padding-top: 0.3em;
    padding-bottom: 8em;
    padding-left: 0.3em;
    color: #000;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1em;
    width: 13em;
  }
  .div-boy {
    margin-top: 1.5em;
  }
  /* 480 */
  .image-boy {
    width: 18em;
  }
  /* 480 */
  .form-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #FDEB1A;
    border-radius: 0.5em;
  }
  /* 480 */
  .text-form-button {
    border-radius: 0.5em;
    margin-top: 0em;
    margin-bottom: 0em;
    margin-left: 0em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-right: 0.2em;
    padding-left: 0.2em;
    color: #1A0A3D;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 1.5em;
    width: 10em;
  }
  /* 480 */
  .div-input-ajuda {
    margin-top: 4em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 2em;
    margin-right: 2em;
    border-radius: 2em;
  }
  /* 480 */
  .div-ajuda {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #0E0326;
    margin-left: 14em;
    margin-right: 14em;
    padding-left: 2em;
    border-radius: 2em;
  }
  /* 480 */
  .text-form-input-ajuda {
    border-radius: 0.5em;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    margin-left: 0em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 0.3em;
    padding-left: 0.5em;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    width: 15em;
    font-size: 1em;
    background-color: #0E0326;
    border: none;
    outline: none;
  }
  /* 480 */
  input::placeholder {
    /* Adicione estilos desejados aqui */
    color: #FFF; /* Cor do texto do placeholder */
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.3em;
  }
  .img-lupa {
    width: 1.5em;
  }
}
@media (max-width:280px) {
}