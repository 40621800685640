
@media (max-width:1920px) {
  .text-title {
    color: #2091C9;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 4em;
  }

  .div-questions {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 2.5em;
  }
  
  .ul-questions {
    padding-bottom: 2rem; /* Adicione espaço após cada item */
    padding-left: 0rem;
  }
  
  .text-question {
    display: flex;
    justify-content: space-between;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 2em;
    text-align: flex-start;
    width: 30em;
  }
  
  .test-response {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1em;
    text-align: start;
  }
  
  .div-response {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50em;
    margin-top: 2em;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.5s ease-in-out;
  }
  
  .margin-top {
    margin-top: 2rem; /* Ajuste o valor conforme necessário */
  }
  
  .text-plan1 {
    display: flex;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    align-items: center;
    line-height: 1; /* Ajuste o valor conforme necessário para diminuir a altura das linhas */
    justify-content: center;
    flex-wrap: wrap;
  }
  /* 768 */
  .text-plan1::before {
    content: "";
    flex-grow: 0.5;
    height: 0.01em;
    background-color: #574877;
    margin-left: 0em ; /* Espaçamento entre a linha e o texto */
    margin-right: 0em ; /* Espaçamento entre a linha e o texto */
  }
  /* 768 */
  .text-plan1::after {
    content: "";
    flex-grow: 0.5;
    height: 0.01em;
    background-color:#574877;
    margin-left: 0em ; /* Espaçamento entre a linha e o texto */
    margin-right: 0em ; /* Espaçamento entre a linha e o texto */
  }
  
  /* Rotaciona o ícone para cima quando o botão está ativo (clicado) */
  .toggle-button.deactive  {
    transform: rotate(180deg);
  }
  
  .div-response.show {
    max-height: 100em; /* Ou outro valor que acomode todo o conteúdo */
    max-width: 100em;
  }

  .toggle-button {
    background-color: rgba(0, 0, 0, 0);
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
    transition: transform 0.8s ease; /* Adicione uma transição para a propriedade transform */
  }
  
  .arrow-button {
    width: 0.8em;
  }
}

@media (max-width:1500px) {
  /* 1500 */
  .text-title {
    color: #2091C9;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 4em;
  }
  /* 1500 */
  .div-questions {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 2.5em;
  }
  /* 1500 */
  .ul-questions {
    padding-bottom: 2rem; /* Adicione espaço após cada item */
    padding-left: 0em;
    padding-right: 0em;
  }
  /* 1500 */
  .text-question {
    display: flex;
    justify-content: space-between;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.6em;
    text-align: flex-start;
    width: 30em;
  }
  /* 1500 */
  .test-response {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 0.8em;
    text-align: start;
  }
  /* 1500 */
  .div-response {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50em;
    margin-top: 2em;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.5s ease-in-out;
  }
  /* 1500 */
  .margin-top {
    margin-top: 2rem; /* Ajuste o valor conforme necessário */
  }
  /* 1500 */
  .text-plan1 {
    display: flex;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    align-items: center;
    line-height: 1; /* Ajuste o valor conforme necessário para diminuir a altura das linhas */
    justify-content: center;
    flex-wrap: wrap;
  }
  /* 1500 */
  .text-plan1::before {
    content: "";
    flex-grow: 0.6;
    height: 0.01em;
    background-color: #574877;
    margin-left: 0em ; /* Espaçamento entre a linha e o texto */
    margin-right: 0em ; /* Espaçamento entre a linha e o texto */
  }
 /* 1500 */
  .text-plan1::after {
    content: "";
    flex-grow: 0.6;
    height: 0.01em;
    background-color: #574877;
    margin-left: 0em ; /* Espaçamento entre a linha e o texto */
    margin-right: 0em ; /* Espaçamento entre a linha e o texto */
  }
  /* 1500 */
  /* Rotaciona o ícone para cima quando o botão está ativo (clicado) */
  button.toggle-button.active .arrow-icon {
    transform: rotate(180deg);
  }
  /* 1500 */
  .toggle-button {
    background-color: rgba(0, 0, 0, 0);
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
  }
/* 1500 */
  .arrow-button {
    width: 0.8em;
  }
}

@media (max-width:1000px) {
  .text-title {
    color: #2091C9;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 4em;
  }
  
  .div-questions {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 2.5em;
  }
  
  .ul-questions {
    padding-bottom: 2rem; /* Adicione espaço após cada item */
    padding-left: 0em;
    padding-right: 0em;
  }
  
  .text-question {
    display: flex;
    justify-content: space-between;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.8em;
    text-align: flex-start;
    width: 22em;
 
  }
  
  .test-response {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1em;
    text-align: start;
  }
  
  .div-response {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 40em;
    margin-top: 2em;
  }
  
  .margin-top {
    margin-top: 2rem; /* Ajuste o valor conforme necessário */
  }
  
  .text-plan1 {
    display: flex;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    align-items: center;
    line-height: 1; /* Ajuste o valor conforme necessário para diminuir a altura das linhas */
    justify-content: center;
    flex-wrap: wrap;
  }
  /* 768 */
  .text-plan1::before {
    content: "";
    flex-grow: 0.6;
    height: 0.01em;
    background-color: #574877;
    margin-left: 0em ; /* Espaçamento entre a linha e o texto */
    margin-right: 0em ; /* Espaçamento entre a linha e o texto */
  }
  /* 768 */
  .text-plan1::after {
    content: "";
    flex-grow: 0.6;
    height: 0.01em;
    background-color: #574877;
    margin-left: 0em ; /* Espaçamento entre a linha e o texto */
    margin-right: 0em ; /* Espaçamento entre a linha e o texto */
  }
  
  /* Rotaciona o ícone para cima quando o botão está ativo (clicado) */
  button.toggle-button.active .arrow-icon {
    transform: rotate(180deg);
  }
  
  .toggle-button {
    background-color: rgba(0, 0, 0, 0);
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
  }
  
  .arrow-button {
    width: 0.8em;
  }
}

@media (max-width:760px) {
  .text-title {
    color: #2091C9;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 4em;
  }
  
  .div-questions {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 2.5em;
  }
  
  .ul-questions {
    padding-bottom: 2rem; /* Adicione espaço após cada item */
    padding-left: 0em;
    padding-right: 0em;
  }
  
  .text-question {
    display: flex;
    justify-content: space-between;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.6em;
    text-align: flex-start;
    width: 12em;
  }
  
  .test-response {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 0.em;
    text-align: start;
  }
  
  .div-response {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 16em;
    margin-top: 2em;
  }
  
  .margin-top {
    margin-top: 2rem; /* Ajuste o valor conforme necessário */
  }
  
  .text-plan1 {
    display: flex;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    align-items: center;
    line-height: 1; /* Ajuste o valor conforme necessário para diminuir a altura das linhas */
    justify-content: center;
    flex-wrap: wrap;
  }
  /* 768 */
  .text-plan1::before {
    content: "";
    flex-grow: 0.6;
    height: 0.01em;
    background-color: #574877;
    margin-left: 0em ; /* Espaçamento entre a linha e o texto */
    margin-right: 0em ; /* Espaçamento entre a linha e o texto */
  }
  /* 768 */
  .text-plan1::after {
    content: "";
    flex-grow: 0.6;
    height: 0.01em;
    background-color: #574877;
    margin-left: 0em ; /* Espaçamento entre a linha e o texto */
    margin-right: 0em ; /* Espaçamento entre a linha e o texto */
  }
  
  /* Rotaciona o ícone para cima quando o botão está ativo (clicado) */
  button.toggle-button.active .arrow-icon {
    transform: rotate(180deg);
  }
  
  .toggle-button {
    background-color: rgba(0, 0, 0, 0);
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
  }
  
  .arrow-button {
    width: 0.8em;
  }
}

@media (max-width:540px) {
  .text-title {
    color: #2091C9;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 4em;
  }
  
  .div-questions {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 2.5em;
  }
  
  .ul-questions {
    padding-bottom: 2rem; /* Adicione espaço após cada item */
    padding-left: 0em;
    padding-right: 0em;
  }
  
  .text-question {
    display: flex;
    justify-content: space-between;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.6em;
    text-align: flex-start;
    width: 12em;
  }
  
  .test-response {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 0.em;
    text-align: start;
  }
  
  .div-response {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 16em;
    margin-top: 2em;
  }
  
  .margin-top {
    margin-top: 2rem; /* Ajuste o valor conforme necessário */
  }
  
  .text-plan1 {
    display: flex;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    align-items: center;
    line-height: 1; /* Ajuste o valor conforme necessário para diminuir a altura das linhas */
    justify-content: center;
    flex-wrap: wrap;
  }
  /* 768 */
  .text-plan1::before {
    content: "";
    flex-grow: 0.6;
    height: 0.01em;
    background-color: #574877;
    margin-left: 0em ; /* Espaçamento entre a linha e o texto */
    margin-right: 0em ; /* Espaçamento entre a linha e o texto */
  }
  /* 768 */
  .text-plan1::after {
    content: "";
    flex-grow: 0.6;
    height: 0.01em;
    background-color: #574877;
    margin-left: 0em ; /* Espaçamento entre a linha e o texto */
    margin-right: 0em ; /* Espaçamento entre a linha e o texto */
  }
  
  /* Rotaciona o ícone para cima quando o botão está ativo (clicado) */
  button.toggle-button.active .arrow-icon {
    transform: rotate(180deg);
  }
  
  .toggle-button {
    background-color: rgba(0, 0, 0, 0);
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
  }
  
  .arrow-button {
    width: 0.8em;
  }
}

@media (max-width:480px) {
  .text-title {
    color: #2091C9;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 4em;
  }
  
  .div-questions {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 2.5em;
  }
  
  .ul-questions {
    padding-left: 0em;
    padding-right: 0em;
  }
  
  .text-question {
    display: flex;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.5em;
    text-align: flex-start;
    width: 12em;
  }
  
  .test-response {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 0.8em;
    text-align: start;
  }
  
  .div-response {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 16em;
    margin-top: 2em;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.5s ease-in-out;
  }

  .div-response.show {
    max-height: 100em; /* Ou outro valor que acomode todo o conteúdo */
    max-width: 100em;
  }
  
  .margin-top {
    margin-top: 2rem; /* Ajuste o valor conforme necessário */
  }
  
  .text-plan1 {
    font-size: 2em;
    align-items: center;
    line-height: 1; /* Ajuste o valor conforme necessário para diminuir a altura das linhas */
    justify-content: center;
    white-space: nowrap;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    padding-top: 0em;
    padding-bottom: 0em;
  }
  /* 768 */
  .text-plan1::before {
    content: "";
    flex-grow: 0.6;
    height: 0.02em;
    background-color: #574877;
    margin-left: 0em ; /* Espaçamento entre a linha e o texto */
    margin-right: 0em ; /* Espaçamento entre a linha e o texto */
  }
  /* 768 */
  .text-plan1::after {
    content: "";
    flex-grow: 0.6;
    height: 0.02em;
    background-color: #574877;
    margin-left: 0em ; /* Espaçamento entre a linha e o texto */
    margin-right: 0em ; /* Espaçamento entre a linha e o texto */
  }
  
  /* Rotaciona o ícone para cima quando o botão está ativo (clicado) */


  .toggle-button.deactive  {
    transform: rotate(180deg);
  }
  
  .toggle-button {
    background-color: rgba(0, 0, 0, 0);
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
    transition: transform 0.8s ease; /* Adicione uma transição para a propriedade transform */
  }
  
  .arrow-button {
    width: 0.8em;
  }
}

@media (max-width:280px) {
  .text-title {
    color: #2091C9;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 4em;
  }
  
  .div-questions {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 2.5em;
  }
  
  .ul-questions {
    padding-bottom: 2rem; /* Adicione espaço após cada item */
    padding-left: 0em;
    padding-right: 0em;
  }
  
  .text-question {
    display: flex;
    justify-content: space-between;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.6em;
    text-align: flex-start;
    width: 12em;
  }
  
  .test-response {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 0.em;
    padding-bottom: 1em;
    margin-top: -2em;
    margin-bottom: 2em;
    text-align: start;
  }
  
  .div-response {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 16em;
    margin-top: 2em;
  }
  
  .margin-top {
    margin-top: 2rem; /* Ajuste o valor conforme necessário */
  }
  
  .text-plan1 {
    display: flex;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    align-items: center;
    line-height: 1; /* Ajuste o valor conforme necessário para diminuir a altura das linhas */
    justify-content: center;
    flex-wrap: wrap;
  }
  /* 768 */
  .text-plan1::before {
    content: "";
    flex-grow: 0.6;
    height: 0.01em;
    background-color: #574877;
    margin-left: 0em ; /* Espaçamento entre a linha e o texto */
    margin-right: 0em ; /* Espaçamento entre a linha e o texto */
  }
  /* 768 */
  .text-plan1::after {
    content: "";
    flex-grow: 0.6;
    height: 0.01em;
    background-color: #574877;
    margin-left: 0em ; /* Espaçamento entre a linha e o texto */
    margin-right: 0em ; /* Espaçamento entre a linha e o texto */
  }
  
  /* Rotaciona o ícone para cima quando o botão está ativo (clicado) */
  button.toggle-button.active .arrow-icon {
    transform: rotate(180deg);
  }
  
  .toggle-button {
    background-color: rgba(0, 0, 0, 0);
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
  }
  
  .arrow-button {
    width: 0.8em;
  }
}